/* Mobile settings */

header h1 {
    color: white;
}


/* NAVBAR mobile*/

#opener:hover {
    cursor: pointer;
}

#opener:active .navbar-collapse {
    display: block;
}


/* 
Navigation auf mobile ausgeklappt 
Standardmäßig nicht sichtbar ers bei hover
*/

.navbar-collapse {
    display: none;
}

.navbar {
    letter-spacing: 2px;
    z-index: 1000;
    width: 100%;
    position: fixed;
    height: 55px;
    top: 0px;
    color: white;
}


/*
  navbar after scrolling down
*/

.navbar-color {
    background-color: #001427DD;
    -webkit-box-shadow: 0px 6px 27px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 27px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
}


/*
  navbar on start
*/

.navbar-transparent {
    /*background-color: #5A6352;*/
}

.menu-icon ul {
    padding: 0px;
}

.navbar a {
    text-decoration: none;
    color: white;
}

.navbar a:hover {
    color: gray;
}

.mobile-menu-heading a {
    color: white;
}

.navbar ul {
    list-style-type: none;
    padding-top: 40px;
    padding-left: 15px;
}

.navbar li {
    height: 20px;
}


/* side menu mobile*/

.open-drawer {
    top: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background-color: rgb(67, 83, 110);
    padding: 15px;
    height: 100%;
    z-index: 2000;
    width: 100%;
    position: fixed;
    display: block;
    opacity: 0.97;
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: transform .15s ease-in-out;
    -moz-transition: transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
}


/* position sichtbar des drawers kommt von oben rein */

.open-drawer-active {
    transform: translate3d(0, 0, 0);
    -webkit-transition: transform 1s ease-in-out;
    -moz-transition: transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
}

.open-drawer li {
    text-align: left;
    margin-bottom: 20px;
    border-bottom: 1px;
}

.menu-icon {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}

.menu-open-icon {
    font-size: 30px;
    margin-top: 15px;
}

.menu-open-icon:hover {
    cursor: pointer;
}

.closeicon {
    display: true;
    padding: 15px;
    color: white;
}

.cart {
    color: black;
}


/*Desktop Navbar*/

@media(min-width:800px) {
    .cart:hover {
        cursor: pointer;
    }
    .closeicon {
        display: none;
        color: white;
    }
    .open-drawer {
        display: none;
    }
    .navbar {
        letter-spacing: 2px;
        width: 100%;
    }
    .navbar-collapse {
        display: flex;
        justify-content: space-between;
    }
    .navbar .menu-icon {
        display: none;
    }
    .navbar ul {
        list-style-type: none;
        padding-top: 0px;
        align-content: right;
    }
    .navbar li {
        display: inline;
        margin-right: 20px;
    }
    .navbar a {
        color: white;
    }
    .navbar a:hover {
        color: darkgray;
    }
    navbar-header {
        padding-top: 7px;
        padding-right: 7px;
    }
}