* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body,
.container {
    margin: 0;
    height: 100vh;
    color: black;
}

body {
    font-family: 'Oxygen', sans-serif;
   
}

h1, h2, h3{
    font-family: 'Bevan', cursive;
}

p {
    font-size: 1em;
}

a {
    text-decoration: none;
    color: black;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 1.9em;
}

.shadow {
    -webkit-box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
    box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
}

.imageShadow {
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

.footer {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    background-color: #72BDCC;
    padding: 40px;
    color: black;
}

@media(min-width:800px) {
    h1 {
        font-size: 4em;
        font-weight: 900;
    }
    h2 {
        font-size: 3em;
        font-weight: 700;
    }
    h3 {
        font-size: 2em;
        font-weight: 700;
    }
    p {
        font-size: 1em;
    }
    .heading h1 {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .heading h2 {
        font-weight: 200;
        font-size: 50px;
    }
}