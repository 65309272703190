.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 100vh;
}

@media(min-width:600px) {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        height: 100vh;
        padding: 200px;
    }
}